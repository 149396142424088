@import "styles/global.scss";

.product-image {
  display: flex;
  justify-content: center;
  width: 100%;
}

.product-details {
  width: 100%;
  margin-top: 25px;
}

.product-details-image-container {
  margin: 0 auto;
  padding: 20px 0;
  width: 250px;
}

.product-details-image {
  width: fit-content;
}

.product-details-section {
  margin-top: 25px;
}

.product-details-name {
  font-size: 20px;
  text-transform: uppercase;
  cursor: pointer;
}

.product-details-copy-icon {
  margin-left: 10px;
  font-size: 15px;
  cursor: pointer;
}

.product-details-price {
  font-size: 35px;
}

.product-details-price span {
  font-size: 30px;
  margin-left: 20px;
  color: $color-gray-light;
  text-decoration: line-through;
}

.product-details-description {
  display: block;
  padding: 10px;
  margin: 0 0 10px;
  font-size: 13px;
  background-color: $color-gray-concrete;
  border: 1px solid $color-gray-iron;
  border-radius: 4px;
}

.product-details-stock {
  margin-left: 15px;
}

.product-details-stock-option {
  margin-top: 15px;
}

.product-details-out-of-stock {
  text-align: center;
  color: $color-red;
  font-size: 25px;
}

.img-holder {
  position: relative;
}
.img-holder .linkphotos {
    position: absolute;
    width: 10%;
    font-size: 10px;
    bottom: 10px; /*your button position*/
    right: 90px; /*your button position*/
}