@import "styles/global.scss";

.minimum-purchase {
  margin-top: 30px;
  color: $color-red;
}

@media only screen and (max-width: 767px) {
  .checkout-cart-summary {
    margin-top: 30px;
  }
}
