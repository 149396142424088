@import "styles/global.scss";

.input-search {
  padding: 0.2rem 50px 0.2rem 0.5rem !important;
}
.btn-search{
  position: absolute;
  bottom: 0;
  right: 0;
  min-width: 45px;  
  height: 28px;
  padding: 0;
  border-radius: 0.25rem;
  float: right;
}

.btn-search-set {
  position: absolute;
  bottom: 0;
  right: 0;
  min-width: 100px;
  height: 28px;
  padding: 0;
  border-radius: 0.25rem;
  float: right;
  span {
    font-size: 0.7em;
  }
}

.btn-search-reset {
  position: absolute;
  bottom: 0;
  right: 0;
  min-width: 45px;  
  height: 28px;
  padding: 0;
  border-radius: 0.25rem;
  float: right;
}

.search-items {
  font-size: 14px;
  width: 85%;
  height: 318px;
  border: 1px solid $color-gray-concrete;
  padding-top: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.search-items::-webkit-scrollbar {
  display: none;
}

.search-empty {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.search-item {
  cursor: pointer;
}

.search-item-info {
  padding-left: 0;
  padding-right: 30px;
}

.search-item-image {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 75px;
  }
}

.search-item-title {
  color: $color-black-text;
}

.search-item-description {
  color: $color-gray;
}

.search-item-price {
  font-weight: 500;
  color: $color-base;
}

.search-item-price span {
  font-size: 12px;
  margin-left: 10px;
  color: $color-gray-light;
  text-decoration: line-through;
}

@media only screen and (max-width: 767px) {
  .search-items {
    height: 333px;
  }

  .search-item-info {
    padding: 20px 30px 0 30px !important;
  }
}

@media only screen and (max-width: 479px) {
  .search-items {
    height: 356px;
  }
}
