$color-base: #822e48;
$color-base-hover: #b46a81;

/* Color Palette */
$color-black: #000;
$color-black-light: #33333b;
$color-black-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
$color-black-text: #1e1e27;

$color-blue-dark: #35425b;
$color-blue-light: #599cdc;

$color-gray: #7f7f7f;
$color-gray-concrete: #f2f2f2;
$color-gray-dark: #51545f;
$color-gray-iron: #d3d3d6;
$color-gray-light: #b5aec4;

$color-green: #38a700;
$color-green-whatsapp: #4dc247;

$color-pink-light: #fde0db;

$color-red: #f00;

$color-white: #fff;

/* Fonts */
$font-family-text: 'Poppins', sans-serif;
$font-family-icon: 'FontAwesome';
